import { makeStyles, Switch as MuiSwitch } from "@material-ui/core";
import { MouseEventHandler, ReactNode } from "react";
import SmallCheckIcon from "../../icons/SmallCheckIcon";
import CloseIcon from "../../icons/CloseIcon";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  closeIconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "6px",
    color: "#fff",
    backgroundColor: "#D54141",
    borderRadius: "50%",
    height: "24px",
    width: "24px"
  },
  checkIconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1px",
    color: "#fff",
    backgroundColor: "#25BE69",
    borderRadius: "50%",
    height: "24px",
    width: "24px"
  },
  disabled: {
    backgroundColor: "#bdbdbd"
  }
}));

interface SwitchProps {
  icon?: ReactNode;
  checkedIcon?: ReactNode;
  checked?: boolean;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onMouseDown?: MouseEventHandler<HTMLButtonElement>;
}

const Switch = ({ icon, checkedIcon, checked, disabled, onClick, onMouseDown }: SwitchProps) => {
  const classes = useStyles();

  return (
    <MuiSwitch
      checked={checked}
      icon={
        <div className={clsx(classes.closeIconContainer, { [classes.disabled]: disabled })}>
          {icon || <CloseIcon size={12} color="#FFF" stroke strokeWidth="4" />}
        </div>
      }
      checkedIcon={
        <div className={clsx(classes.checkIconContainer, { [classes.disabled]: disabled })}>
          {checkedIcon || <SmallCheckIcon size={22} backgroundColor="none" color="#FFF" stroke />}
        </div>
      }
      disableRipple
      disabled={disabled}
      onClick={onClick}
      onMouseDown={onMouseDown}
    />
  );
};

export default Switch;
