import { Button, FormControl, InputLabel, makeStyles, MenuItem, Select } from "@material-ui/core";
import { useVideoContext } from "../../../../VideoProvider";
import { Pause, PlayArrowOutlined } from "@material-ui/icons";
import { useAppState } from "../../../../../state";
import SpeakersTestAudio from "../../../../../assets/SpeakersTestAudio.mp3";
import clsx from "clsx";
import { useMemo, useRef, useState } from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "flex-end",
    gap: 16,
    marginBottom: 8
  },
  selectContainer: {
    width: "60%"
  },
  label: {
    fontSize: 16,
    fontWeight: 600
  },
  testButton: {
    color: "#3f52ff",
    borderColor: "#3f52ff"
  }
}));

interface AudioOutputControlProps {
  className?: string;
}

const AudioOutputControl = ({ className }: AudioOutputControlProps) => {
  const classes = useStyles();

  const { audioOutputDevices, localTracks, isAcquiringLocalTracks } = useVideoContext();
  const { activeSinkId, setActiveSinkId } = useAppState();

  const testSpeakerRef = useRef<HTMLAudioElement | null>(null);
  const [isPlayingTest, setIsPlayingTest] = useState(false);

  const handlePlayTest = (stop?: boolean) => {
    if (testSpeakerRef.current) {
      if (isPlayingTest || stop) {
        testSpeakerRef.current.pause();
        testSpeakerRef.current.currentTime = 0;
      } else {
        testSpeakerRef.current.muted = false;
        testSpeakerRef.current.play();
        setTimeout(() => handlePlayTest(true), 16000);
      }

      setIsPlayingTest(!isPlayingTest);
    }
  };

  const isDisabled = isAcquiringLocalTracks || !localTracks.some((track) => track.kind === "audio");

  const outputDevices = useMemo(
    () => [
      // Safari won't have any devices unless enabled by user in settings
      { deviceId: "default", label: "System Default" },
      ...audioOutputDevices
    ],
    [audioOutputDevices]
  );

  return (
    <div className={clsx(classes.container, className)}>
      <FormControl className={classes.selectContainer}>
        <InputLabel className={classes.label} id="audio-output-select" shrink>
          Select Speakers
        </InputLabel>
        <Select
          fullWidth
          disabled={isDisabled}
          name="audio-output-select"
          label=""
          value={activeSinkId}
          onChange={(e) => setActiveSinkId(e.target.value as string)}
        >
          {outputDevices.map((device) => (
            <MenuItem value={device.deviceId} key={device.deviceId}>
              {device.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        className={classes.testButton}
        type="button"
        variant="outlined"
        size="small"
        endIcon={isPlayingTest ? <Pause /> : <PlayArrowOutlined />}
        disableRipple
        disabled={isDisabled}
        onClick={() => handlePlayTest()}
        onMouseDown={(e) => e.stopPropagation()}
        aria-describedby="video-input-switch-background-button"
      >
        Test
      </Button>
      <audio src={SpeakersTestAudio} ref={testSpeakerRef} loop />
    </div>
  );
};

export default AudioOutputControl;
