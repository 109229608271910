import { useMemo } from "react";
import { getBrowser, Browser } from "../../../../../utils/browser";
import { isMobile } from "../../../../../utils";

const StepByStepGuideEmbed = () => {
  const browser = useMemo(getBrowser, []);

  switch (browser) {
    case Browser.Chrome:
      return (
        <div
          style={{
            position: "relative",
            width: "100%",
            paddingTop: isMobile ? "213%" : "128%",
            paddingBottom: 0,
            overflow: "hidden",
            willChange: "transform"
          }}
        >
          <iframe
            title="Chrome Step-by-Step Guide"
            loading="lazy"
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              border: "none",
              padding: 0,
              margin: 0
            }}
            src={
              isMobile
                ? "https://www.canva.com/design/DAGYG8GVvtc/3Urc2FewDdJTxShVib-lsg/view?embed"
                : "https://www.canva.com/design/DAGXK8IrbGU/pCVDIcMWmk3e4bVuzv_XvA/view?embed"
            }
            allowFullScreen
            allow="fullscreen"
          ></iframe>
        </div>
      );

    case Browser.Edge:
      return (
        <div
          style={{
            position: "relative",
            width: "100%",
            height: 0,
            paddingTop: "128%",
            paddingBottom: 0,
            overflow: "hidden",
            willChange: "transform"
          }}
        >
          <iframe
            title="Edge Step-by-Step Guide"
            loading="lazy"
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              border: "none",
              padding: 0,
              margin: 0
            }}
            src="https://www.canva.com/design/DAGXcp5juRg/32IgPzibDLbRBCQcYOk8Ww/view?embed"
            allowFullScreen
            allow="fullscreen"
          ></iframe>
        </div>
      );

    case Browser.Firefox:
      return (
        <div
          style={{
            position: "relative",
            width: "100%",
            height: 0,
            paddingTop: "128%",
            paddingBottom: 0,
            overflow: "hidden",
            willChange: "transform"
          }}
        >
          <iframe
            title="Firefox Step-by-Step Guide"
            loading="lazy"
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              border: "none",
              padding: 0,
              margin: 0
            }}
            src="https://www.canva.com/design/DAGXcpn1uwc/NPs8uhFWA4Zc3zoyJmhmRg/view?embed"
            allowFullScreen
            allow="fullscreen"
          ></iframe>
        </div>
      );

    case Browser.Safari:
      return (
        <div
          style={{
            position: "relative",
            width: "100%",
            height: 0,
            paddingTop: isMobile ? "213%" : "128%",
            paddingBottom: 0,
            overflow: "hidden",
            willChange: "transform"
          }}
        >
          <iframe
            title="Safari Step-by-Step Guide"
            loading="lazy"
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              border: "none",
              padding: 0,
              margin: 0
            }}
            src={
              isMobile
                ? "https://www.canva.com/design/DAGYGmgqZfY/CnsmssHmVdtjq7WR-hqPcQ/view?embed"
                : "https://www.canva.com/design/DAGXczE6RHY/SjV5EPqJxjdwFLS47M35sw/view?embed"
            }
            allowFullScreen
            allow="fullscreen"
          ></iframe>
        </div>
      );

    default:
      return (
        <div
          style={{
            position: "relative",
            width: "100%",
            height: 0,
            paddingTop: "128%",
            paddingBottom: 0,
            overflow: "hidden",
            willChange: "transform"
          }}
        >
          <iframe
            title="Generic Step-by-Step Guide"
            loading="lazy"
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              border: "none",
              padding: 0,
              margin: 0
            }}
            src="https://www.canva.com/design/DAGXdDqSGHY/rgGblfGgA81Urax7xKzTNQ/view?embed"
            allowFullScreen
            allow="fullscreen"
          ></iframe>
        </div>
      );
  }
};

export default StepByStepGuideEmbed;
