import { ClinicianMedicareRole } from "../mbsItems";
import { CLINICAL_PSYCHOLOGIST_ITEMS } from "./clinicalPsychologist";
import { PSYCHOLOGIST_ITEMS } from "./psychologist";

/** without disabled items */
export const DVA_ITEMS = {
  [ClinicianMedicareRole.ClinicalPsychologists]: [...CLINICAL_PSYCHOLOGIST_ITEMS, ...PSYCHOLOGIST_ITEMS].filter(
    ({ disabled }) => !disabled
  ),
  [ClinicianMedicareRole.RegisteredPsychologists]: [...CLINICAL_PSYCHOLOGIST_ITEMS, ...PSYCHOLOGIST_ITEMS].filter(
    ({ disabled }) => !disabled
  ),
  [ClinicianMedicareRole.OccupationalTherapists]: [],
  [ClinicianMedicareRole.SocialWorkers]: [],
  [ClinicianMedicareRole.GeneralPractitioner]: [],
  [ClinicianMedicareRole.Dietitian]: [],
  [ClinicianMedicareRole.PracticeNurse]: [],
  [ClinicianMedicareRole.MentalHealthNurse]: [],
  [ClinicianMedicareRole.NursePractitioner]: [],
  [ClinicianMedicareRole.SpeechPathologist]: [],

  // generic items
  [ClinicianMedicareRole.MentalHealthWorker]: [],
  [ClinicianMedicareRole.Psychologist]: [],
  [ClinicianMedicareRole.AlliedHealthProfessional]: []
};
