import { Button, makeStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import StepByStepGuideEmbed from "./StepByStepGuideEmbed";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 8,
    marginBottom: 12,
    fontWeight: 700,
    fontSize: 15,
    color: "#414449",

    "& svg": {
      color: "#00109f"
    },

    [theme.breakpoints.down("sm")]: {
      padding: 12,
      marginBottom: 0
    }
  },
  closeButton: {
    minWidth: "unset",
    padding: 0
  },
  embed: {
    width: "100%",
    maxWidth: 400,
    height: "max-content",
    maxHeight: 512,
    overflow: "auto",
    backgroundColor: "#d9d9d9",
    borderRadius: 4,

    [theme.breakpoints.down("sm")]: {
      maxWidth: "unset",
      maxHeight: "unset",
      borderRadius: 0
    }
  }
}));

interface StepByStepGuideContentProps {
  onClose: () => void;
}

const StepByStepGuideContent = ({ onClose }: StepByStepGuideContentProps) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.title}>
        Guide to resolving connection issues
        <Button className={classes.closeButton} variant="text" onClick={onClose}>
          <Close />
        </Button>
      </div>
      <div className={classes.embed}>
        <StepByStepGuideEmbed />
      </div>
    </>
  );
};

export default StepByStepGuideContent;
