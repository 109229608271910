import {
  ClinicianMedicareRole,
  MedicareItem,
  MedicareItemDuration,
  MedicareItemFormat,
  MedicareItemMode
} from "../mbsItems/interfaces";

// DVA reference: https://www.dva.gov.au/sites/default/files/2024-07/psychologistfees-1-jul-2024.pdf
export const PSYCHOLOGIST_ITEMS: MedicareItem[] = [
  {
    mbsCode: "US19",
    description: "Consultation 20-50 Minutes - Video Conference",
    benefit: 112.95,
    role: ClinicianMedicareRole.RegisteredPsychologists,
    duration: MedicareItemDuration.OneToOneMoreThan20,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.VideoCall
  },
  {
    mbsCode: "US20",
    description: "Consultation 50+ Minutes - Video Conference",
    benefit: 159.55,
    role: ClinicianMedicareRole.RegisteredPsychologists,
    duration: MedicareItemDuration.OneToOneMoreThan50,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.VideoCall
  },
  {
    mbsCode: "US41",
    description: "Consultation 20-50 Minutes - Phone Consultation",
    benefit: 112.95,
    role: ClinicianMedicareRole.RegisteredPsychologists,
    duration: MedicareItemDuration.OneToOneMoreThan20,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall
  },
  {
    mbsCode: "US42",
    description: "Consultation 50+ Minutes - Phone Consultation",
    benefit: 159.55,
    role: ClinicianMedicareRole.RegisteredPsychologists,
    duration: MedicareItemDuration.OneToOneMoreThan50,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall
  }
];
