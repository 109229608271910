import { useState, useEffect, useCallback } from "react";
import DeviceSelectionScreen from "./DeviceSelectionScreen/DeviceSelectionScreen";
import IntroContainer from "../IntroContainer/IntroContainer";
import MediaErrorSnackbar from "./MediaErrorSnackbar/MediaErrorSnackbar";
import RoomNameScreen from "./RoomNameScreen/RoomNameScreen";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useVideoContext } from "../VideoProvider";
import { useAppState } from "../../state";
import { TelehealthRoomType } from "../../services/schedService";

export enum Steps {
  roomNameStep,
  deviceSelectionStep
}

export default function PreJoinScreens() {
  const { accountSettings, appointment, user } = useAppState();
  const { mediaError, hasAttemptedAcquireLocalTracks, acquireLocalMedia, initialiseTelehealthRoomType } =
    useVideoContext();
  const { URLRoomName } = useParams<{ URLRoomName?: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [step, setStep] = useState(Steps.roomNameStep);

  const [name, setName] = useState(searchParams.get("name") || "");
  const [roomName, setRoomName] = useState("");

  const handleAcquireLocalMedia = useCallback(
    async (telehealthRoomType: TelehealthRoomType) => {
      if (!hasAttemptedAcquireLocalTracks) {
        await acquireLocalMedia(telehealthRoomType);
      }
    },
    [acquireLocalMedia, hasAttemptedAcquireLocalTracks]
  );

  const handleRoomNameSubmit = async (roomId: string) => {
    const telehealthRoomType = await initialiseTelehealthRoomType(roomId);

    // only if not logged in
    if (!user) {
      setSearchParams((params) => {
        params.set("name", name);
        return params;
      });
    }

    window.history.replaceState(
      null,
      "",
      window.encodeURI(
        `${user?.isClinician && !user.joinAsParticipant ? "/clinicians" : ""}/room/${roomId}${
          window.location.search || ""
        }`
      )
    );
    setStep(Steps.deviceSelectionStep);
    handleAcquireLocalMedia(telehealthRoomType);
  };

  useEffect(() => {
    if (URLRoomName) {
      if (URLRoomName.endsWith(".")) {
        navigate(`/room/${URLRoomName.slice(0, -1)}`);
      }

      setRoomName(URLRoomName);

      if (user && step !== Steps.deviceSelectionStep) {
        handleRoomNameSubmit(URLRoomName);
      } else if (!accountSettings || !appointment) {
        initialiseTelehealthRoomType(URLRoomName);
      }
    }

    // adding handleRoomNameSubmit to this list would cause infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountSettings, user, URLRoomName, navigate, handleAcquireLocalMedia, initialiseTelehealthRoomType]);

  return step === Steps.deviceSelectionStep ? (
    <DeviceSelectionScreen name={user?.nickname || name} roomName={roomName} />
  ) : (
    <IntroContainer>
      <MediaErrorSnackbar error={mediaError} />
      {step === Steps.roomNameStep && (
        <RoomNameScreen
          name={user?.nickname || name}
          roomName={URLRoomName || roomName}
          setName={setName}
          setRoomName={setRoomName}
          hideRoomNameField={!!URLRoomName}
          onSubmit={handleRoomNameSubmit}
        />
      )}
    </IntroContainer>
  );
}
