import { FormControl, InputLabel, makeStyles, MenuItem, Select } from "@material-ui/core";
import { useVideoContext } from "../../../../VideoProvider";
import { LocalAudioTrack } from "twilio-video";
import { SELECTED_AUDIO_INPUT_KEY } from "../../../../../constants";
import useMediaStreamTrack from "../../../../../hooks/useMediaStreamTrack/useMediaStreamTrack";
import Switch from "../../../../Switch/Switch";
import { Mic, MicNoneOutlined } from "@material-ui/icons";
import useLocalAudioToggle from "../../../../../hooks/useLocalAudioToggle/useLocalAudioToggle";
import AudioLevelIndicatorBar from "../../../../AudioLevelIndicatorBar/AudioLevelIndicatorBar";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "flex-end",
    gap: 16,
    marginBottom: 8
  },
  selectContainer: {
    width: "60%"
  },
  label: {
    fontSize: 16,
    fontWeight: 600
  },
  switchContainer: {
    padding: "5px 0"
  },
  switchIcon: {
    width: "18px !important",
    height: "18px !important"
  },
  audioLevelIndicatorContainer: {
    display: "flex",
    alignItems: "center",
    width: "60%"
  },
  audioLevelIndicator: { flex: 1 }
}));

interface AudioInputControlProps {
  className?: string;
}

const AudioInputControl = ({ className }: AudioInputControlProps) => {
  const classes = useStyles();

  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks, audioInputDevices, isAcquiringLocalTracks } = useVideoContext();

  const localAudioTrack = localTracks.find((track) => track.kind === "audio") as LocalAudioTrack;
  const srcMediaStreamTrack = localAudioTrack?.noiseCancellation?.sourceTrack;
  const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
  const localAudioInputDeviceId =
    srcMediaStreamTrack?.getSettings().deviceId || mediaStreamTrack?.getSettings().deviceId;

  const replaceTrack = (newDeviceId: string) => {
    window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId);
    localAudioTrack?.restart({ deviceId: { exact: newDeviceId } });
  };

  const isDisabled = isAcquiringLocalTracks || !localTracks.some((track) => track.kind === "audio");

  return (
    <div className={className}>
      <div className={classes.container}>
        <FormControl className={classes.selectContainer}>
          <InputLabel className={classes.label} id="audio-input-select" shrink>
            Select Microphone
          </InputLabel>
          <Select
            fullWidth
            disabled={isDisabled}
            name="audio-input-select"
            label=""
            value={localAudioInputDeviceId || ""}
            onChange={(e) => replaceTrack(e.target.value as string)}
          >
            {audioInputDevices.map((device) => (
              <MenuItem value={device.deviceId} key={device.deviceId}>
                {device.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className={classes.switchContainer}>
          <Switch
            icon={<Mic className={classes.switchIcon} />}
            checkedIcon={<Mic className={classes.switchIcon} />}
            checked={isAudioEnabled}
            disabled={isDisabled}
            onClick={toggleAudioEnabled}
            onMouseDown={(e) => e.stopPropagation()}
          />
        </div>
      </div>
      <div className={classes.audioLevelIndicatorContainer}>
        <MicNoneOutlined htmlColor="#36A163" />
        <AudioLevelIndicatorBar className={classes.audioLevelIndicator} audioTrack={localAudioTrack} />
      </div>
    </div>
  );
};

export default AudioInputControl;
