import { useMemo, useRef, useState } from "react";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Theme,
  Typography,
  makeStyles,
  useMediaQuery
} from "@material-ui/core";
import UserAvatar from "./UserAvatar/UserAvatar";
import { ExitToApp, Schedule } from "@mui/icons-material";
import moment from "moment";
import { StateContextType, useAppState } from "../../../state";
import { OpenInNew } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    zIndex: 20,
    top: 0,
    right: 0,
    margin: "1em",
    display: "flex",
    alignItems: "center",
    gap: 16
  },
  userContainer: {
    display: "flex",
    alignItems: "center"
  },
  userButton: {
    color: "black"
  },
  backToTacklitButton: {
    color: "#3f52ff",
    border: "1px solid #3f52ff",
    borderRadius: 4
  },

  callListContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
    padding: 12
  },
  callListTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 16,
    marginBottom: 8
  },
  callListTitleText: {
    fontSize: 10,
    fontWeight: 700,
    color: "#94969D"
  },
  callListTitleDate: {
    color: "#414449"
  },
  callListTitleLink: {
    fontSize: 12,
    color: "#3f52ff",
    textDecoration: "none",

    "&:hover": {
      textDecoration: "underline"
    }
  },
  callItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 8px",
    backgroundColor: "#f5f6ff",
    border: "1px solid #dadbde",
    borderRadius: 4
  },
  callItemTime: {
    flex: 1,
    marginRight: 16,
    whiteSpace: "nowrap",
    fontSize: 12,
    color: "#414449"
  },
  callItemName: {
    flex: 1,
    marginRight: 24,
    fontSize: 12,
    fontWeight: 600,
    color: "#414449"
  },
  callItemLink: {
    display: "flex",
    alignItems: "center",
    gap: 4,
    color: "#3f52ff",
    textDecoration: "none",

    "& span": {
      fontSize: 12,
      textDecoration: "none"
    },
    "&:hover span": {
      textDecoration: "underline"
    },
    "& svg": {
      fontSize: 24
    },

    [theme.breakpoints.down("sm")]: {
      "& span": {
        display: "none"
      }
    }
  }
}));

interface UserMenuProps {
  user: Required<StateContextType>["user"];
  signOut?: () => void;
}

const UserMenu = ({ user, signOut }: UserMenuProps) => {
  const classes = useStyles();

  const { todayAppointments } = useAppState();

  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const userMenuRef = useRef<HTMLButtonElement>(null);

  const [isCallListMenuOpen, setIsCallListMenuOpen] = useState(false);
  const callListRef = useRef<HTMLButtonElement>(null);

  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down?.("sm"));

  const { careOsDomain, calendarLink } = useMemo(() => {
    const userAccessConfig = user?.["https://tacklit.com/accessConfig"];
    const isEdgeUser = userAccessConfig?.plan === "edge";
    const isEdgeAdmin = isEdgeUser && userAccessConfig?.accessRight === "admin";
    const isEdgeReceptionist = isEdgeUser && userAccessConfig?.accessRight === "receptionist";
    const isEdgeMentor = isEdgeUser && userAccessConfig?.accessRight === "mentor";

    const careOsDomain = user.careOsDomain || process.env.REACT_APP_CLINICIAN_UI_URL;

    const initPath = isEdgeUser ? (isEdgeAdmin ? "/admin" : isEdgeReceptionist ? "/receptionist" : "/user") : "";

    const calendarLink = `${careOsDomain}${isEdgeMentor ? "" : `${initPath || ""}/calendar`}`;

    return {
      careOsDomain,
      calendarLink
    };
  }, [user]);

  return (
    <div className={classes.container}>
      <div className={classes.userContainer}>
        <UserAvatar user={user} />
        <Button onClick={() => setIsUserMenuOpen((isOpen) => !isOpen)} ref={userMenuRef} className={classes.userButton}>
          {user.nickname}
        </Button>
        <Menu
          open={isUserMenuOpen}
          onClose={() => setIsUserMenuOpen((isOpen) => !isOpen)}
          anchorEl={userMenuRef.current}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <MenuItem onClick={signOut}>
            <Typography variant="body1">Logout</Typography>
          </MenuItem>
        </Menu>
      </div>

      {user.isClinician &&
        (isMobile ? (
          <>
            <IconButton ref={callListRef} color="primary" onClick={() => setIsCallListMenuOpen((isOpen) => !isOpen)}>
              <Schedule />
            </IconButton>
            <a href={careOsDomain}>
              <IconButton className={classes.backToTacklitButton}>
                <ExitToApp />
              </IconButton>
            </a>
          </>
        ) : (
          <>
            <Button
              ref={callListRef}
              variant="contained"
              color="primary"
              startIcon={<Schedule />}
              onClick={() => setIsCallListMenuOpen((isOpen) => !isOpen)}
            >
              Today's call list
            </Button>
            <a href={careOsDomain}>
              <Button className={classes.backToTacklitButton} variant="outlined" startIcon={<ExitToApp />}>
                Back to Tacklit
              </Button>
            </a>
          </>
        ))}
      <Popover
        open={isCallListMenuOpen}
        onClose={() => setIsCallListMenuOpen(false)}
        anchorEl={callListRef.current}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <div className={classes.callListContainer}>
          <div className={classes.callListTitle}>
            <div className={classes.callListTitleText}>
              TODAY:{" "}
              <span className={classes.callListTitleDate}>{moment().format("ddd DD/MM/YYYY").toUpperCase()}</span>
            </div>
            <a className={classes.callListTitleLink} href={calendarLink} rel="noopener noreferrer" target="_blank">
              Open Full Calendar
            </a>
          </div>
          {todayAppointments.length ? (
            todayAppointments.map(({ startDateTime, endDateTime, clientName, telehealthLink }, i) => (
              <div className={classes.callItem} key={i}>
                <div className={classes.callItemTime}>
                  {startDateTime} - {endDateTime}
                </div>
                <div className={classes.callItemName}>{clientName}</div>
                <a className={classes.callItemLink} href={telehealthLink} rel="noopener noreferrer" target="_blank">
                  <span>{telehealthLink}</span>
                  <OpenInNew />
                </a>
              </div>
            ))
          ) : (
            <div className={classes.callItem}>
              <div className={classes.callItemTime}>No appointments today.</div>
            </div>
          )}
        </div>
      </Popover>
    </div>
  );
};

export default UserMenu;
