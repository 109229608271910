import {
  ClinicianMedicareRole,
  MedicareItem,
  MedicareItemDuration,
  MedicareItemFormat,
  MedicareItemMode
} from "../mbsItems/interfaces";

// DVA reference: https://www.dva.gov.au/sites/default/files/2024-07/clinpsychfees-1-jul-2024.pdf
export const CLINICAL_PSYCHOLOGIST_ITEMS: MedicareItem[] = [
  {
    mbsCode: "US09",
    description: "Consultation 30-50 Minutes - Video Conference",
    benefit: 159.55,
    role: ClinicianMedicareRole.ClinicalPsychologists,
    duration: MedicareItemDuration.OneToOneMoreThan20,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.VideoCall
  },
  {
    mbsCode: "US10",
    description: "Consultation 50+ Minutes - Video Conference",
    benefit: 234.2,
    role: ClinicianMedicareRole.ClinicalPsychologists,
    duration: MedicareItemDuration.OneToOneMoreThan50,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.VideoCall
  },
  {
    mbsCode: "US43",
    description: "Consultation 30-50 Minutes - Phone Consultation",
    benefit: 159.55,
    role: ClinicianMedicareRole.ClinicalPsychologists,
    duration: MedicareItemDuration.OneToOneMoreThan20,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall
  },
  {
    mbsCode: "US44",
    description: "Consultation 50+ Minutes - Phone Consultation",
    benefit: 234.2,
    role: ClinicianMedicareRole.ClinicalPsychologists,
    duration: MedicareItemDuration.OneToOneMoreThan50,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall
  }
];
