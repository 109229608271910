const getUserAgent = () => {
  return typeof navigator !== "undefined" && typeof navigator.userAgent === "string" ? navigator.userAgent : null;
};

export enum Browser {
  Chrome = "chrome",
  Edge = "edge",
  Firefox = "firefox",
  Safari = "safari"
}

export const getBrowser = () => {
  const userAgent = getUserAgent();

  if (!userAgent) {
    return;
  }

  if (/Chrome|CriOS/.test(userAgent)) {
    // test for edge with chrome
    if (/Edge/.test(navigator.userAgent)) {
      return typeof window.chrome !== "undefined" && typeof window.chrome.runtime !== "undefined"
        ? Browser.Edge
        : undefined;
    } else {
      return Browser.Chrome;
    }
  }
  if (/Firefox|FxiOS/.test(userAgent)) {
    return Browser.Firefox;
  }
  if (/Safari|iPhone|iPad|iPod/.test(userAgent)) {
    return Browser.Safari;
  }
};
