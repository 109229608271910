import { useState, useEffect, useCallback } from "react";
import { getDeviceInfo } from "../../utils";

// This returns the type of the value that is returned by a promise resolution
type ThenArg<T> = T extends PromiseLike<infer U> ? U : never;

export type DeviceInfo = ThenArg<ReturnType<typeof getDeviceInfo>>;

export default function useDevices() {
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>({
    audioInputDevices: [],
    videoInputDevices: [],
    audioOutputDevices: [],
    hasAudioInputDevices: false,
    hasVideoInputDevices: false
  });

  const getDevices = useCallback(() => getDeviceInfo().then((devices) => setDeviceInfo(devices)), []);

  useEffect(() => {
    getDevices();

    navigator.mediaDevices.addEventListener("devicechange", getDevices);

    return () => {
      navigator.mediaDevices.removeEventListener("devicechange", getDevices);
    };
  }, [getDevices]);

  return { ...deviceInfo, getDevices };
}
