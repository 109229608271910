import { useMediaQuery, Theme, makeStyles } from "@material-ui/core";
import { Dialog } from "@mui/material";
import StepByStepGuideContent from "./StepByStepGuideContent";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    padding: 12,
    overflow: "auto",

    [theme.breakpoints.down("sm")]: {
      padding: 0
    }
  }
}));

interface StepByStepGuideProps {
  open: boolean;
  onClose: () => void;
}

const StepByStepGuide = ({ open, onClose }: StepByStepGuideProps) => {
  const classes = useStyles();

  const isFullscreen = useMediaQuery<Theme>((theme) => theme.breakpoints.down?.("sm"));

  return isFullscreen ? (
    <Dialog fullScreen open={open} onClose={onClose}>
      <div className={classes.container}>
        <StepByStepGuideContent onClose={onClose} />
      </div>
    </Dialog>
  ) : open ? (
    <div className={classes.container}>
      <StepByStepGuideContent onClose={onClose} />
    </div>
  ) : null;
};

export default StepByStepGuide;
