import { CircularProgress, Grid, Typography, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useVideoContext } from "../VideoProvider";
import Background from "../Background/Background";
import AudioInputSwitch from "../InputSwitches/AudioInputSwitch/AudioInputSwitch";
import AudioOutputSwitch from "../InputSwitches/AudioOutputSwitch/AudioOutputSwitch";
import VideoInputSwitch from "../InputSwitches/VideoInputSwitch/VideoInputSwitch";
import LocalVideoPreview from "../PreJoinScreens/DeviceSelectionScreen/components/LocalVideoPreview/LocalVideoPreview";
import Snackbar from "../Snackbar/Snackbar";
import { TelehealthRoomType } from "../../services/schedService";
import { useAppState } from "../../state";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: "1",
    display: "flex",
    width: "95vw",
    maxWidth: "900px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "4px 4px 8px 4px rgba(0, 0, 0, 0.1)",
    position: "relative",
    margin: "auto",
    padding: "60px 30px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "calc(100% - 40px)",
      height: "fit-content",
      margin: "55px auto 20px auto",
      padding: "40px 20px",
      maxWidth: "400px"
    }
  },

  // left side
  leftContainer: {
    width: "calc(70% - 40px)",
    marginRight: "40px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: 0
    }
  },
  title: {
    marginBottom: "8px",
    fontSize: "28px",
    fontWeight: 600,
    wordBreak: "break-all"
  },
  psychologist: {
    marginBottom: "40px",
    color: "#3F52FF",
    fontSize: "18px",
    fontWeight: 600
  },
  clinicianRedirect: {
    textDecoration: "underline",
    "&:visited": {
      color: "#3F52FF"
    }
  },
  description: {
    marginBottom: "16px",
    fontSize: "20px"
  },

  // right side
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    height: "max-content",
    width: "30%",
    marginTop: "-12px",
    padding: "12px",
    paddingTop: "calc(50px + 2em)",
    borderRadius: "4px",
    background: "#f9f9fa",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      paddingTop: "12px"
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "2em",
      borderRadius: 0
    }
  },
  localPreviewContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    transform: "translateY(calc(-100% + 50px))",
    width: "100%",
    marginBottom: "2em",
    borderRadius: "4px",
    [theme.breakpoints.down("md")]: {
      position: "relative",
      transform: "none",
      width: "auto",
      borderRadius: 0
    }
  },
  videoInputSwitchContainer: {
    marginBottom: "12px"
  },
  audioInputSwitchContainer: {
    marginBottom: "12px"
  },
  audioOutputSwitchContainer: {
    marginBottom: "24px"
  },
  toolTipContainer: {
    display: "flex",
    alignItems: "center",
    "& div": {
      display: "flex",
      alignItems: "center"
    },
    "& svg": {
      marginLeft: "0.3em"
    }
  }
}));

const WaitingRoom = () => {
  const classes = useStyles();
  const { URLRoomName } = useParams<{ URLRoomName?: string }>();

  const { user } = useAppState();
  const { telehealthRoomType, isAcquiringLocalTracks, isConnecting, userParticipant } = useVideoContext();

  const [openNotification, setOpenNotification] = useState(true);

  useEffect(() => {
    const promptBeforeExit = (ev: BeforeUnloadEvent) => {
      ev.preventDefault();
      ev.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", promptBeforeExit);
    return () => {
      window.removeEventListener("beforeunload", promptBeforeExit);
    };
  }, []);

  if (!userParticipant) {
    return null;
  }

  return (
    <Background>
      <Snackbar
        variant="info"
        headline="You are in a waiting room."
        message="Please wait, the host will let you in soon"
        open={openNotification}
        handleClose={() => setOpenNotification(false)}
      />

      <div className={classes.container}>
        {isConnecting ? (
          <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: "100%" }}>
            <div>
              <CircularProgress variant="indeterminate" size={24} />
            </div>
            <div>
              <Typography variant="body2" style={{ fontWeight: "bold", fontSize: "16px" }}>
                Joining session...
              </Typography>
            </div>
          </Grid>
        ) : (
          <>
            <div className={classes.leftContainer}>
              <div className={classes.title}>Welcome {userParticipant.name}</div>
              {!user?.isClinician && (
                <div className={classes.psychologist}>
                  You have joined as a client, are you a practitioner host?{" "}
                  <a
                    className={classes.clinicianRedirect}
                    href={`/clinicians${URLRoomName ? `/room/${URLRoomName}` : ""}`}
                  >
                    Click here
                  </a>
                </div>
              )}
              <div className={classes.description}>
                You are in Room <b>{URLRoomName}</b>
              </div>
              <div className={classes.description}>You are currently in the waiting room.</div>
              {!user?.isClinician && (
                <div className={classes.description}>
                  Your practitioner will let you in when your session is ready to start.
                </div>
              )}
            </div>
            <div className={classes.rightContainer}>
              <div className={classes.localPreviewContainer}>
                <LocalVideoPreview identity={userParticipant.name} showIdentity />
              </div>

              {telehealthRoomType === TelehealthRoomType.Video && (
                <VideoInputSwitch className={classes.videoInputSwitchContainer} disabled={isAcquiringLocalTracks} />
              )}
              <AudioInputSwitch className={classes.audioInputSwitchContainer} disabled={isAcquiringLocalTracks} />
              <AudioOutputSwitch className={classes.audioOutputSwitchContainer} />
              <a
                href="https://tacklit.helpscoutdocs.com/article/365-telehealth-set-up"
                target="_blank"
                rel="noreferrer"
              >
                Need help getting set up? Try this step by step guide.
              </a>
            </div>
          </>
        )}
      </div>
    </Background>
  );
};

export default WaitingRoom;
