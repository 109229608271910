const getNameFromClientProfile = (
  clientData: { firstName?: string; lastName?: string; name?: string },
  firstNameOnly?: boolean
): string => {
  const { firstName, lastName, name } = clientData;
  if (firstName && lastName) {
    return firstNameOnly ? firstName : `${firstName} ${lastName}`;
  }
  return name || "";
};

export const combineClientProfileNames = (
  clientProfiles: { firstName?: string; lastName?: string; name?: string }[],
  firstNameOnly?: boolean,
  useSymbol?: boolean
) => {
  return clientProfiles
    .map((val) => getNameFromClientProfile(val, firstNameOnly))
    .join(", ")
    .replace(/,(?!.*,)/gim, ` ${useSymbol ? "&" : " and"}`);
};
