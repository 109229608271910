import { makeStyles, Typography, Grid, Button, Link } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import LocalVideoPreview from "./components/LocalVideoPreview/LocalVideoPreview";
import { useVideoContext } from "../../VideoProvider";
import { useAppState } from "../../../state";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import Background from "../../Background/Background";
import clsx from "clsx";
import { OpenInNew, Refresh, ReportProblemOutlined } from "@material-ui/icons";
import { GppGoodOutlined, InfoOutlined } from "@mui/icons-material";
import VideoControl from "./components/VideoControl/VideoControl";
import AudioInputControl from "./components/AudioInputControl/AudioInputControl";
import AudioOutputControl from "./components/AudioOutputControl/AudioOutputControl";
import { TelehealthRoomType } from "../../../services/schedService";
import Hidden from "../../Hidden/Hidden";
import { useCallback, useEffect, useMemo, useState } from "react";
import Snackbar from "../../Snackbar/Snackbar";
import StepByStepGuide from "./components/StepByStepGuide/StepByStepGuide";
import { Browser, getBrowser } from "../../../utils/browser";
import PermissionsErrorSnackbarMessage from "./components/PermissionsErrorSnackbarMessage/PermissionsErrorSnackbarMessage";

const useStyles = makeStyles((theme) => ({
  background: {
    paddingTop: "calc(44px + 1em)",

    [theme.breakpoints.down("sm")]: {
      background: "#fff"
    }
  },
  container: {
    flex: "1",
    display: "flex",
    width: "95vw",
    maxWidth: "min(95vw, 1028px)",
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "-4px 4px 8px 0px rgba(0, 0, 0, 0.1)",
    position: "relative",
    margin: "auto",
    padding: "60px 0 60px 30px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "calc(100% - 40px)",
      // height: "fit-content",
      margin: "55px auto 20px auto",
      padding: "40px 20px",
      maxWidth: "min(95vw, 400px)",
      backgroundColor: "transparent",
      boxShadow: "none"
    }
  },

  // left side
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "calc(60% - 40px)",
    marginRight: "40px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: 0
    }
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    gap: 24,
    marginBottom: "24px",
    fontSize: "24px",
    fontWeight: 600,
    wordBreak: "break-word",

    [theme.breakpoints.down("sm")]: {
      fontSize: "18px"
    }
  },
  testVideoButton: {
    display: "flex",
    alignItems: "center",
    gap: 8,
    height: "max-content",
    padding: "8px 12px",
    fontSize: 14,
    fontWeight: 700,
    color: "#00109f",
    wordBreak: "normal",
    whiteSpace: "nowrap",
    backgroundColor: "#6575b126",
    borderRadius: 4,
    transition: "0.1s ease",

    "&:hover": {
      backgroundColor: "#6575b144",
      textDecoration: "none"
    }
  },
  testVideoTextDesktop: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  testVideoTextMobile: {
    display: "none",

    [theme.breakpoints.down("sm")]: {
      display: "inline-flex"
    }
  },
  description: {
    marginBottom: "16px",
    fontSize: "18px",
    fontWeight: 600,

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px"
    }
  },
  deviceStatuses: {
    display: "flex",
    alignItems: "center"
  },
  deviceStatus: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    marginBottom: "16px"
  },
  deviceStatusIcon: {
    width: 20,
    height: 20,
    marginRight: 8,
    borderRadius: "50%",

    "&.connected": {
      backgroundColor: "#00bf63"
    },
    "&.disconnected": {
      backgroundColor: "#fd8468"
    }
  },
  deviceStatusLabel: {
    fontSize: "12px",
    fontWeight: 600
  },
  refreshContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 24,
    fontSize: "14px",
    fontWeight: 400
  },
  refreshText: {
    color: "#314bcb"
  },
  refreshButton: {
    flex: 1
  },
  refreshButtonText: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  refreshButtonIcon: {
    marginLeft: 8,

    [theme.breakpoints.down("sm")]: {
      marginLeft: 0
    }
  },
  hr: {
    width: "100%",
    marginTop: 24,
    marginBottom: 32
  },
  enterRoomTitleDesktop: {
    fontSize: "20px",
    fontWeight: 600
  },
  enterRoomDescriptionDesktop: {
    marginTop: 12,
    fontSize: "16px",
    fontWeight: 400
  },
  errorDescription: {
    display: "flex",
    alignItems: "center",
    gap: 8,
    marginTop: 12,
    color: "#d54141",
    fontSize: "14px",
    fontWeight: 600
  },
  enterRoomButtonDesktop: {
    width: "100%",
    marginTop: 20
  },

  // right side
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    height: "max-content",
    width: "40%",
    borderRadius: "4px",
    background: "#fffbf2",
    boxShadow: "0px 0px 8px 3px rgba(0, 0, 0, 0.1)",
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      transform: "none",
      width: "100%",
      marginTop: "2em",
      borderRadius: 0
    },

    "&.hasError": {
      background: "#fff8f8"
    }
  },
  rightContent: {
    padding: "12px",

    "&.hideOnDesktop": {
      display: "none",

      [theme.breakpoints.down("sm")]: {
        display: "block"
      }
    }
  },
  sectionTitle: {
    marginBottom: 16,
    fontSize: "16px",
    fontWeight: 700
  },
  control: {
    marginBottom: 16
  },
  localPreviewContainer: {
    marginBottom: 20,
    borderRadius: 4,
    overflow: "hidden"
  },
  info: {
    display: "flex",
    gap: 12,
    padding: 12,
    fontSize: "12px",
    fontWeight: 600,
    background: "#fff",
    borderRadius: 4
  },
  helpButton: {
    display: "contents",
    fontSize: "12px",
    fontWeight: 600,
    color: "#3f52ff",

    "&:hover span": {
      textDecoration: "underline"
    }
  },
  noDevice: {
    display: "flex",
    gap: 8,
    marginBottom: 20,
    padding: "16px 8px",
    backgroundColor: "#ffe5e5",
    borderRadius: 4,

    "& svg": {
      fontSize: 20,
      color: "#d54141"
    }
  },
  noDeviceTitle: {
    marginBottom: 8,
    fontSize: 14,
    fontWeight: 600,
    color: "#d54141"
  },
  noDeviceDescription: {
    fontSize: 12,
    fontWeight: 600,
    color: "#343434",

    "&:not(:last-child)": {
      marginBottom: 8
    }
  },
  noDeviceButton: {
    marginBottom: 12,
    backgroundColor: "#fff",

    "& span": {
      gap: 4
    }
  },

  enterRoomTitleMobile: {
    display: "none",
    marginTop: 32,
    fontSize: "20px",
    fontWeight: 600,

    [theme.breakpoints.down("sm")]: {
      display: "block"
    }
  },
  enterRoomDescriptionMobile: {
    display: "none",
    marginTop: 12,
    fontSize: "12px",
    fontWeight: 400,

    [theme.breakpoints.down("sm")]: {
      display: "block"
    }
  },
  errorDescriptionMobile: {
    display: "none",
    alignItems: "center",
    gap: 8,
    marginTop: 12,
    color: "#d54141",
    fontSize: "14px",
    fontWeight: 600,

    [theme.breakpoints.down("sm")]: {
      display: "flex"
    }
  },
  enterRoomButtonMobile: {
    display: "none",
    width: "100%",
    marginTop: 20,

    [theme.breakpoints.down("sm")]: {
      display: "inline-flex"
    }
  }
}));

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
}

const DeviceSelectionScreen = ({ name, roomName }: DeviceSelectionScreenProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const { accountSettings, user } = useAppState();
  const {
    localTracks,
    mediaError,
    telehealthRoomType,
    hasVideoInputDevices,
    isConnecting,
    isFetching,
    acquireLocalMedia,
    joinRoom
  } = useVideoContext();

  const [isPermissionsErrorSnackbarOpen, setIsPermissionsErrorSnackbarOpen] = useState(false);
  const [isStepByStepGuideOpen, setIsStepByStepGuideOpen] = useState(false);

  useEffect(() => {
    if (mediaError) {
      setIsPermissionsErrorSnackbarOpen(true);
    }
  }, [mediaError]);

  const { disableJoining, hasAudioTrack, isVideoCall } = useMemo(() => {
    const isVideoCall = telehealthRoomType === TelehealthRoomType.Video;

    // logic here is a bit weird, but has to do with how video track is turned on and off:
    // https://github.com/tacklit/tele-ui/blob/master/src/hooks/useLocalVideoToggle/useLocalVideoToggle.tsx#L13
    // `toggleVideoEnabled` completely removes the video track when turning off video,
    // and re-gets the video track completely when turning on.
    // https://github.com/tacklit/tele-ui/blob/master/src/hooks/useLocalAudioToggle/useLocalAudioToggle.tsx#L11
    // on the other hand, `toggleAudioEnabled` just disables and enables the audio track
    // this means that:
    // audio check is whether the audio track is present or not
    // video check is just whether there are available video devices or not, because
    // `getLocalVideoTrack` just takes whatever's available
    // https://github.com/tacklit/tele-ui/blob/master/src/components/VideoProvider/useLocalTracks/useLocalTracks.ts#L25
    const hasAudioTrack = localTracks.some((track) => track.kind === "audio");
    // if is voice only, must have audio
    // if is video call, must have either audio or video
    const disableJoining = !hasAudioTrack && (!isVideoCall || !hasVideoInputDevices);

    return { disableJoining, hasAudioTrack, isVideoCall };
  }, [hasVideoInputDevices, localTracks, telehealthRoomType]);

  const handleAllowPermission = useCallback(async () => {
    if (getBrowser() === Browser.Safari) {
      // If on Safari, rechecking permissions results in glitches so just refresh the page
      window.location.reload();
    } else {
      // Try and get permissions again
      await acquireLocalMedia(telehealthRoomType!, true);
    }
  }, [acquireLocalMedia, telehealthRoomType]);

  const handleJoin = async () => {
    const parsedSearch = queryString.parse(location.search);

    if (accountSettings?.preSessionFormId && !user?.isClinician && parsedSearch.skipForms !== "1") {
      navigate(`/room/${roomName}/survey/${accountSettings.preSessionFormId}`, {
        state: {
          name,
          roomName,
          joinRoom: true,
          redirectPath: window.location.pathname + window.location.search
        }
      });
    } else {
      await joinRoom(name, roomName);
    }
  };

  const handleStepToStepGuideClick = () => {
    setIsPermissionsErrorSnackbarOpen(false);
    setIsStepByStepGuideOpen(true);
  };

  return (
    <Background className={classes.background}>
      <Snackbar
        open={isPermissionsErrorSnackbarOpen}
        handleClose={() => setIsPermissionsErrorSnackbarOpen(false)}
        headline={`Unable to acquire permissions`}
        message={<PermissionsErrorSnackbarMessage onStepToStepGuideClick={handleStepToStepGuideClick} />}
        variant="error"
      />
      <div className={classes.container}>
        {isFetching || isConnecting ? (
          <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: "100%" }}>
            <div>
              <CircularProgress variant="indeterminate" size={24} />
            </div>
            <div>
              <Typography variant="body2" style={{ fontWeight: "bold", fontSize: "16px" }}>
                Starting your session...
              </Typography>
            </div>
          </Grid>
        ) : (
          <>
            <div className={classes.leftContainer}>
              <div className={classes.title}>
                <span>
                  Welcome {user && "back "} {name}
                </span>
                <Link
                  className={classes.testVideoButton}
                  href={`${window.location.origin}/video-test`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className={classes.testVideoTextDesktop}>Try test call</span>
                  <span className={classes.testVideoTextMobile}>Test call</span>
                  <OpenInNew />
                </Link>
              </div>
              <div className={classes.description}>Please check your audio {isVideoCall && "and video "}setup</div>
              <div className={classes.deviceStatuses}>
                {isVideoCall && (
                  <div className={classes.deviceStatus}>
                    <div
                      className={clsx(classes.deviceStatusIcon, {
                        [hasVideoInputDevices ? "connected" : "disconnected"]: true
                      })}
                    />
                    <div className={classes.deviceStatusLabel}>
                      Camera
                      <Hidden smDown> {hasVideoInputDevices ? "" : "Not"} Connected</Hidden>
                    </div>
                  </div>
                )}
                <div className={classes.deviceStatus}>
                  <div
                    className={clsx(classes.deviceStatusIcon, {
                      [hasAudioTrack ? "connected" : "disconnected"]: true
                    })}
                  />
                  <div className={classes.deviceStatusLabel}>
                    Microphone
                    <Hidden smDown> {hasAudioTrack ? "" : "Not"} Connected</Hidden>
                  </div>
                </div>
              </div>
              <div className={classes.refreshContainer}>
                <div>
                  Not seeing the {isVideoCall && "camera or "}mic you expect?
                  <br />
                  <span className={classes.refreshText}>Refresh your connection to retry</span>
                </div>
                <Button color="secondary" variant="outlined" onClick={() => window.location.reload()}>
                  <span className={classes.refreshButtonText}>Refresh</span>
                  <Refresh className={classes.refreshButtonIcon} />
                </Button>
              </div>
              <Hidden smDown>
                <hr className={classes.hr} />
                <div className={classes.enterRoomTitleDesktop}>
                  Enter room <strong>{roomName}</strong>
                </div>
                {hasAudioTrack &&
                (!isVideoCall || hasVideoInputDevices) &&
                user?.isClinician &&
                !user.joinAsParticipant ? (
                  <div className={classes.enterRoomDescriptionDesktop}>
                    You will have wait room control to let in other participants
                  </div>
                ) : disableJoining ? (
                  <div className={classes.errorDescription}>
                    <ReportProblemOutlined />
                    Unable to {user?.isClinician ? "host" : "join"} a room with no audio{isVideoCall && " or video"}
                  </div>
                ) : !hasAudioTrack ? (
                  <div className={classes.errorDescription}>
                    <ReportProblemOutlined />
                    Starting this session with VIDEO ONLY
                  </div>
                ) : (
                  !hasVideoInputDevices && (
                    <div className={classes.errorDescription}>
                      <ReportProblemOutlined />
                      Starting this session with AUDIO ONLY
                    </div>
                  )
                )}
                <Button
                  className={classes.enterRoomButtonDesktop}
                  color="primary"
                  variant="contained"
                  disabled={disableJoining}
                  onClick={handleJoin}
                >
                  Enter Room
                </Button>
              </Hidden>
            </div>
            <div className={clsx(classes.rightContainer, { hasError: !hasAudioTrack || !hasVideoInputDevices })}>
              <div className={clsx(classes.rightContent, { hideOnDesktop: isStepByStepGuideOpen })}>
                {isVideoCall && (
                  <>
                    <div className={classes.sectionTitle}>Video</div>
                    {hasVideoInputDevices ? (
                      <>
                        <VideoControl className={classes.control} />
                        <div className={classes.localPreviewContainer}>
                          <LocalVideoPreview identity={name} />
                        </div>
                      </>
                    ) : (
                      <div className={classes.noDevice}>
                        <ReportProblemOutlined />
                        <div>
                          <div className={classes.noDeviceTitle}>No video device permitted</div>
                          <div className={classes.noDeviceDescription}>
                            Oh no! We are having trouble accessing your camera. Please click to Allow Permission and
                            grant access via the browser prompt.
                          </div>
                          <Button
                            className={classes.noDeviceButton}
                            color="secondary"
                            variant="outlined"
                            onClick={handleAllowPermission}
                          >
                            Allow Permission
                            <GppGoodOutlined />
                          </Button>
                          <div className={classes.noDeviceDescription}>
                            Still stuck? Please review our{" "}
                            <Button
                              className={classes.helpButton}
                              variant="text"
                              disableRipple
                              onClick={handleStepToStepGuideClick}
                            >
                              <span>step by step help guide</span>
                            </Button>{" "}
                            or contact us if the situation is a bit more tricky.
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}

                <div className={classes.sectionTitle}>Audio</div>
                {hasAudioTrack ? (
                  <>
                    <AudioInputControl className={classes.control} />
                    <AudioOutputControl className={classes.control} />
                  </>
                ) : (
                  <div className={classes.noDevice}>
                    <ReportProblemOutlined />
                    <div>
                      <div className={classes.noDeviceTitle}>No microphone access detected</div>
                      <div className={classes.noDeviceDescription}>
                        Oh no! We are having trouble accessing your microphone. Please click to Allow Permission and
                        grant access via the browser prompt.
                      </div>
                      <Button
                        className={classes.noDeviceButton}
                        color="secondary"
                        variant="outlined"
                        onClick={handleAllowPermission}
                      >
                        Allow Permission
                        <GppGoodOutlined />
                      </Button>
                      <div className={classes.noDeviceDescription}>
                        Still stuck? Please review our{" "}
                        <Button
                          className={classes.helpButton}
                          variant="text"
                          disableRipple
                          onClick={handleStepToStepGuideClick}
                        >
                          <span>step by step help guide</span>
                        </Button>{" "}
                        or contact us if the situation is a bit more tricky.
                      </div>
                    </div>
                  </div>
                )}

                <div className={classes.info}>
                  <InfoOutlined htmlColor="#263054" />
                  <span>
                    Having trouble with your microphone{isVideoCall && " or camera"}? Don’t worry we’re here to help!
                    Please review our{" "}
                    <Button
                      className={classes.helpButton}
                      variant="text"
                      disableRipple
                      onClick={handleStepToStepGuideClick}
                    >
                      <span>step by step help guide</span>
                    </Button>{" "}
                    or contact us if the situation is a bit more tricky.
                  </span>
                </div>
              </div>
              <StepByStepGuide open={isStepByStepGuideOpen} onClose={() => setIsStepByStepGuideOpen(false)} />
            </div>
            <div className={classes.enterRoomTitleMobile}>
              Enter room <strong>{roomName}</strong>
            </div>
            {hasAudioTrack && hasVideoInputDevices && user?.isClinician && !user.joinAsParticipant ? (
              <div className={classes.enterRoomDescriptionMobile}>
                You will have wait room control to let in other participants
              </div>
            ) : disableJoining ? (
              <div className={classes.errorDescriptionMobile}>
                <ReportProblemOutlined />
                Unable to {user?.isClinician ? "host" : "join"} a room with no audio{isVideoCall && " or video"}
              </div>
            ) : !hasAudioTrack ? (
              <div className={classes.errorDescriptionMobile}>
                <ReportProblemOutlined />
                Starting this session with VIDEO ONLY
              </div>
            ) : (
              !hasVideoInputDevices && (
                <div className={classes.errorDescriptionMobile}>
                  <ReportProblemOutlined />
                  Starting this session with AUDIO ONLY
                </div>
              )
            )}
            <Button
              className={classes.enterRoomButtonMobile}
              color="primary"
              variant="contained"
              disabled={disableJoining}
              onClick={handleJoin}
            >
              Enter Room
            </Button>
          </>
        )}
      </div>
    </Background>
  );
};

export default DeviceSelectionScreen;
