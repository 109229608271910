import { Button, FormControl, InputLabel, makeStyles, MenuItem, Popper, Select } from "@material-ui/core";
import { useRef, useState, useEffect, useCallback } from "react";
import useLocalVideoToggle from "../../../../../hooks/useLocalVideoToggle/useLocalVideoToggle";
import { useVideoContext } from "../../../../VideoProvider";
import { LocalVideoTrack } from "twilio-video";
import { DEFAULT_VIDEO_CONSTRAINTS, SELECTED_VIDEO_INPUT_KEY } from "../../../../../constants";
import useMediaStreamTrack from "../../../../../hooks/useMediaStreamTrack/useMediaStreamTrack";
import Hidden from "../../../../Hidden/Hidden";
import BackgroundSelect from "../../../../InputSwitches/VideoInputSwitch/BackgroundSelect/BackgroundSelect";
import clsx from "clsx";
import Switch from "../../../../Switch/Switch";
import { WallpaperOutlined } from "@material-ui/icons";
import { VideoCameraFrontOutlined } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "flex-end",
    gap: 16,

    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap"
    }
  },
  selectContainer: {
    width: "60%"
  },
  label: {
    fontSize: 16,
    fontWeight: 600
  },
  switchContainer: {
    padding: "5px 0"
  },
  switchIcon: {
    width: "18px !important",
    height: "18px !important"
  },
  backgroundButton: {
    minWidth: 104,
    color: "#3f52ff",
    borderColor: "#3f52ff"
  }
}));

interface VideoControlProps {
  className?: string;
}

const VideoControl = ({ className }: VideoControlProps) => {
  const classes = useStyles();

  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const { localTracks, videoInputDevices, isAcquiringLocalTracks } = useVideoContext();

  const lastClickTimeRef = useRef(0);
  const backgroundSelectAnchorRef = useRef<HTMLDivElement | null>(null);
  const popperRef = useRef<HTMLDivElement | null>(null);

  const [isBackgroundSelectVisible, setIsBackgroundSelectVisible] = useState(false);

  const localVideoTrack = localTracks.find((track) => track.kind === "video") as LocalVideoTrack | undefined;
  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
  const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useState(
    window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY)
  );

  const localVideoInputDeviceId = mediaStreamTrack?.getSettings().deviceId || storedLocalVideoDeviceId;

  const handleClick = (e: any) => {
    if (
      !popperRef.current?.contains(e.target) &&
      e.target?.attributes?.["aria-describedby"]?.value !== "video-input-switch-background-button"
    ) {
      setIsBackgroundSelectVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  const replaceTrack = (newDeviceId: string) => {
    setStoredLocalVideoDeviceId(newDeviceId);
    window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
    localVideoTrack?.restart({
      ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
      deviceId: { exact: newDeviceId }
    });
  };

  const isDisabled =
    isAcquiringLocalTracks ||
    (!localTracks.some((track) => track.kind === "video") && !videoInputDevices.some((device) => device.label));

  return (
    <div className={clsx(classes.container, className)} ref={backgroundSelectAnchorRef}>
      <FormControl className={classes.selectContainer}>
        <InputLabel className={classes.label} id="video-input-select" shrink>
          Select Camera
        </InputLabel>
        <Select
          fullWidth
          disabled={isDisabled}
          name="video-input-select"
          label=""
          value={localVideoInputDeviceId || ""}
          onChange={(e) => replaceTrack(e.target.value as string)}
        >
          {videoInputDevices.map((device) => (
            <MenuItem value={device.deviceId} key={device.deviceId}>
              {device.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className={classes.switchContainer}>
        <Switch
          icon={<VideoCameraFrontOutlined className={classes.switchIcon} />}
          checkedIcon={<VideoCameraFrontOutlined className={classes.switchIcon} />}
          checked={isVideoEnabled}
          disabled={isDisabled}
          onClick={toggleVideo}
          onMouseDown={(e) => e.stopPropagation()}
        />
      </div>
      <Button
        className={classes.backgroundButton}
        type="button"
        variant="outlined"
        size="small"
        endIcon={<WallpaperOutlined />}
        disableRipple
        disabled={isDisabled}
        onClick={() => setIsBackgroundSelectVisible(!isBackgroundSelectVisible)}
        onMouseDown={(e) => e.stopPropagation()}
        aria-describedby="video-input-switch-background-button"
      >
        Background
      </Button>

      {backgroundSelectAnchorRef.current && (
        <>
          <Hidden smDown>
            <Popper
              id="video-input-switch-background-button"
              ref={popperRef}
              anchorEl={backgroundSelectAnchorRef.current}
              placement="right-start"
              open={isBackgroundSelectVisible}
              modifiers={{
                offset: {
                  enabled: true,
                  offset: "0,12.5"
                }
              }}
            >
              <BackgroundSelect onClose={() => setIsBackgroundSelectVisible(false)} />
            </Popper>
          </Hidden>
          <Hidden mdUp>
            <Popper
              id="video-input-switch-background-button"
              ref={popperRef}
              anchorEl={backgroundSelectAnchorRef.current}
              placement="bottom"
              open={isBackgroundSelectVisible}
              modifiers={{
                flip: {
                  enabled: false
                }
              }}
            >
              <BackgroundSelect onClose={() => setIsBackgroundSelectVisible(false)} />
            </Popper>
          </Hidden>
        </>
      )}
    </div>
  );
};

export default VideoControl;
