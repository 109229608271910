import { useState } from "react";
import Snackbar from "../../Snackbar/Snackbar";
import { useVideoContext } from "../../VideoProvider";

const getSnackbarContent = ({
  hasAttemptedAcquireLocalTracks,
  hasAudio,
  hasVideo,
  error
}: {
  hasAttemptedAcquireLocalTracks: boolean;
  hasAudio: boolean;
  hasVideo: boolean;
  error?: Error;
}) => {
  let headline = "";
  let message = "";

  if (error) {
    // These custom errors are thrown by the useLocalTracks hook. They are thrown when the user explicitly denies
    // permission to only their camera, or only their microphone.
    if (error.message === "CameraPermissionsDenied") {
      headline = "Camera Access Denied:";
      message = "We are unable to connect to your camera via this browser. Please hit refresh and grant permission.";
    } else if (error.message === "MicrophonePermissionsDenied") {
      headline = "Microphone Access Denied:";
      message =
        "We are unable to connect to your microphone via this browser. Please hit refresh and grant permission.";
    } else if (error.name === "NotAllowedError") {
      // This error is emitted when the user or the user's system has denied permission to use the media devices
      headline = "Microphone and Camera Access Denied:";

      if (error!.message === "Permission denied by system") {
        // Chrome only
        message =
          "This browser is unable to access your microphone or camera due to a system setting on your device. Please check your settings, hit refresh, and grant permission.";
      } else {
        message =
          "We are unable to connect to your microphone and camera via this browser. Please hit refresh and grant permission.";
      }
    } else if (error.name === "NotFoundError") {
      // This error is emitted when input devices are not connected or disabled in the OS settings
      headline = "Cannot Find Microphone or Camera:";
      message =
        "This browser is unable to access your microphone or camera. Please make sure the devices are connected and enabled.";
    } else {
      // Other getUserMedia errors are less likely to happen in this app. Here we will display
      // the system's error message directly to the user.
      headline = "Error Acquiring Media:";
      message = `${error!.name} ${error!.message}`;
    }
  } else if (hasAttemptedAcquireLocalTracks) {
    if (!hasAudio && !hasVideo) {
      headline = "No Camera or Microphone Detected:";
      message = "Other participants in the room will be unable to see and hear you.";
    } else if (!hasVideo) {
      headline = "No Camera Detected:";
      message = "Other participants in the room will be unable to see you.";
    } else if (!hasAudio) {
      headline = "No Microphone Detected:";
      message = "Other participants in the room will be unable to hear you.";
    }
  }

  return {
    headline,
    message
  };
};

export default function MediaErrorSnackbar({ error }: { error?: Error }) {
  const { isAcquiringLocalTracks, hasAttemptedAcquireLocalTracks, hasAudioInputDevices, hasVideoInputDevices } =
    useVideoContext();

  const [isSnackbarDismissed, setIsSnackbarDismissed] = useState(false);

  const isSnackbarOpen =
    !isSnackbarDismissed &&
    !isAcquiringLocalTracks &&
    hasAttemptedAcquireLocalTracks &&
    (Boolean(error) || !hasAudioInputDevices || !hasVideoInputDevices);

  const { headline, message } = getSnackbarContent({
    hasAttemptedAcquireLocalTracks,
    hasAudio: hasAudioInputDevices,
    hasVideo: hasVideoInputDevices,
    error
  });

  return (
    <Snackbar
      open={isSnackbarOpen}
      handleClose={() => setIsSnackbarDismissed(true)}
      headline={headline}
      message={message}
      variant="warning"
    />
  );
}
