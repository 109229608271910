import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState
} from "react";
import {
  // RecordingRules,
  RoomType
} from "../types";
import { TwilioError } from "twilio-video";
import { settingsReducer, initialSettings, Settings, SettingsAction } from "./settings/settingsReducer";
import useActiveSinkId from "./useActiveSinkId/useActiveSinkId";
import { useLocalStorageState } from "../hooks/useLocalStorageState/useLocalStorageState";
import { User as Auth0User, useAuth0 } from "@auth0/auth0-react";
import { AxiosError } from "axios";
import { Practice, UserProfile, getProfile } from "../services/clinicianProfileService";
import { MAX_PARTICIPANTS_PER_GALLERY } from "../constants";
import Loader from "../components/Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { AppointmentSlots } from "../components/ProcessAppointmentForm/helpers";
import { AccountSettings, ClientRecordType, DeliveryType, getClinicianAppointments } from "../services/schedService";
import queryString from "query-string";
import moment from "moment";
import momentTz from "moment-timezone";
import { combineClientProfileNames } from "../utils/general";

export interface SuccessContent {
  name: string;
  message: string;
}

interface User extends Auth0User {
  isClinician?: boolean;
  practice?: Practice;
  // Skips auto-redirect to clinician page, joins waitroom like a participant
  joinAsParticipant?: boolean;
  careOsDomain?: string;
  timezone?: string;
}

interface Appointment {
  startDateTime: string;
  endDateTime: string;
  clientName: string;
  telehealthLink: string;
}

export interface StateContextType {
  user?: User;
  error: TwilioError | Error | null;
  setError(error: TwilioError | Error | null): void;
  success: SuccessContent | null;
  setSuccess(success: SuccessContent | null): void;
  // signIn?(passcode?: string): Promise<void>;
  signOut?(): void;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: Dispatch<SettingsAction>;
  roomType?: RoomType;
  setRoomType: (roomType?: RoomType) => void;
  // updateRecordingRules: (room_sid: string, rules: RecordingRules) => Promise<object>;
  isGalleryViewActive: boolean;
  setIsGalleryViewActive: Dispatch<SetStateAction<boolean | undefined>>;
  maxGalleryViewParticipants: number;
  setMaxGalleryViewParticipants: Dispatch<SetStateAction<number | undefined>>;
  appointment?: AppointmentSlots;
  setAppointment: Dispatch<SetStateAction<AppointmentSlots | undefined>>;
  appointmentClinician?: UserProfile;
  setAppointmentClinician: Dispatch<SetStateAction<UserProfile | undefined>>;
  accountSettings?: AccountSettings;
  setAccountSettings: Dispatch<SetStateAction<AccountSettings | undefined>>;
  todayAppointments: Appointment[];
}

export const StateContext = createContext<StateContextType | null>(null);

interface AppStateProviderProps {
  children?: ReactNode | undefined;
}

const AppStateProvider = (props: AppStateProviderProps) => {
  const { user: auth0User, logout, getAccessTokenSilently, isLoading: isAuthLoading, isAuthenticated } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState<User | undefined>();
  const [error, setError] = useState<TwilioError | Error | null>(null);
  const [success, setSuccess] = useState<SuccessContent | null>(null);
  const [isGalleryViewActive, setIsGalleryViewActive] = useLocalStorageState("gallery-view-active-key", true);
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);
  const [roomType, setRoomType] = useState<RoomType>();
  const [todayAppointments, setTodayAppointments] = useState<Appointment[]>([]);
  const [appointment, setAppointment] = useState<AppointmentSlots>();
  const [appointmentClinician, setAppointmentClinician] = useState<UserProfile>();
  const [accountSettings, setAccountSettings] = useState<AccountSettings>();
  const [maxGalleryViewParticipants, setMaxGalleryViewParticipants] = useLocalStorageState(
    "max-gallery-participants-key",
    MAX_PARTICIPANTS_PER_GALLERY
  );

  const signOut = () => {
    if (!auth0User) {
      return;
    }
    return logout({
      logoutParams: {
        returnTo: window.location.origin
      }
    });
  };

  /**
   * Syncing user profile data from cps
   */
  const syncProfile = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();

      const clinicianProfileResponse = await getProfile(token);
      const { avatar, name, practice, workTimeZone, accountSettings } = clinicianProfileResponse.data;

      const timezone =
        workTimeZone ||
        accountSettings?.microsoftActiveDirectorySettings?.inviteRedirectUrl ||
        process.env.REACT_APP_DEFAULT_TIMEZONE ||
        "";

      setUser((user) => ({
        ...user,
        isClinician: true,
        nickname: name,
        picture: avatar,
        practice: practice,
        careOsDomain: accountSettings?.microsoftActiveDirectorySettings?.inviteRedirectUrl,
        timezone
      }));

      const today = moment().format("YYYY-MM-DD");

      const appointmentsResponse = await getClinicianAppointments({
        token,
        accountId: clinicianProfileResponse.data.accountId,
        clinicianId: clinicianProfileResponse.data._id,
        from: today,
        to: today
      });

      setTodayAppointments(
        appointmentsResponse.data
          .filter(
            (
              appointment
            ): appointment is Omit<typeof appointment, "clientRecord" | "telehealthRoomId"> &
              Pick<Required<typeof appointment>, "clientRecord" | "telehealthRoomId"> =>
              !!appointment.clientRecord &&
              [DeliveryType.PhoneCall, DeliveryType.PhoneCallDialClient, DeliveryType.VideoCall].includes(
                appointment.deliveryType
              ) &&
              !!appointment.telehealthRoomId
          )
          .map(({ _id, deliveryType, startDateTime, endDateTime, clientRecord, telehealthRoomId }) => {
            const mainClientProfiles =
              clientRecord.recordType === ClientRecordType.Child
                ? clientRecord.clientProfiles.filter((clientProfile) => clientProfile.role === "child")
                : clientRecord.clientProfiles;

            const telehealthLink =
              deliveryType === DeliveryType.PhoneCallDialClient
                ? `${window.location.origin}/calls/${_id}`
                : `${window.location.origin}/room/${telehealthRoomId}?type=${
                    deliveryType === DeliveryType.PhoneCall
                      ? "voice"
                      : deliveryType === DeliveryType.VideoCall
                      ? "video"
                      : ""
                  }`;

            return {
              startDateTime: momentTz.tz(startDateTime, timezone).format("hh:mmA"),
              endDateTime: momentTz.tz(endDateTime, timezone).format("hh:mmA"),
              clientName: combineClientProfileNames(
                mainClientProfiles,
                clientRecord.recordType === ClientRecordType.Couple,
                true
              ),
              telehealthLink
            };
          })
      );
    } catch (ex) {
      if (ex instanceof AxiosError) {
        return setError({
          code: ex.response?.status && typeof ex.response.status === "number" ? ex.response.status : 500,
          message: ex.response?.data?.message || ex.message,
          name: ex.name
        });
      }
      setUser((user) => ({
        ...user,
        // syncProfile should only be called for clinician logins
        // setting isClinician: true in case backend throws 500 or something
        isClinician: true
      }));
    }
  }, [getAccessTokenSilently]);

  /**
   * For checking logged-in user:
   * - If client and at clinician route, redirect to non-clinician route
   * - If clinician and at client route, redirect to clinician route
   * - If not logged in, nothing
   */
  useEffect(() => {
    if (!auth0User || isAuthLoading || !isAuthenticated) {
      return;
    }

    // redirect to client path
    if (auth0User?.["https://tacklit.com/roles"].includes("patient")) {
      setUser({
        ...auth0User,
        nickname: auth0User.name,
        isClinician: false
      });

      if (location.pathname.startsWith("/clinicians")) {
        navigate(location.pathname.replace("/clinicians", ""));
      }
      return;
    }

    if (!auth0User.email_verified) {
      window.location.href = `${process.env.REACT_APP_CLINICIAN_UI_URL}/unverified`;
    } else if (!location.pathname.includes("process")) {
      const parsedSearch = queryString.parse(location.search);

      const joinAsParticipant = parsedSearch.joinAsParticipant === "1";

      // Cheat to force don't re-fetch profile
      setUser({
        ...auth0User,
        nickname: "Loading...",
        isClinician: true,
        joinAsParticipant
      });
      syncProfile();

      if (
        !location.pathname.startsWith("/clinicians") &&
        !location.pathname.startsWith("/calls") &&
        !joinAsParticipant
      ) {
        navigate(`/clinicians${location.pathname}`);
      }
    }
  }, [auth0User, isAuthLoading, isAuthenticated, location.pathname, location.search, navigate, syncProfile]);

  return (
    <StateContext.Provider
      value={{
        user,
        signOut,
        error,
        setError,
        success,
        setSuccess,
        activeSinkId,
        setActiveSinkId,
        settings,
        dispatchSetting,
        roomType,
        setRoomType,
        isGalleryViewActive,
        setIsGalleryViewActive,
        maxGalleryViewParticipants,
        setMaxGalleryViewParticipants,
        appointment,
        setAppointment,
        appointmentClinician,
        setAppointmentClinician,
        accountSettings,
        setAccountSettings,
        todayAppointments
      }}
    >
      {isAuthLoading ? <Loader /> : props.children}
    </StateContext.Provider>
  );
};

type AppStateReturnType<T extends boolean> = T extends true ? StateContextType : StateContextType | null;

export const useAppState = <T extends boolean = true>(
  expectInitialised: T = true as T
): AppStateReturnType<T> | never => {
  const context = useContext(StateContext);

  if (!context && expectInitialised) {
    throw new Error("useAppState must be used within the AppStateProvider");
  }

  return context as AppStateReturnType<T>;
};

export default AppStateProvider;
