import { ChangeEvent } from "react";
import { Typography, makeStyles, TextField, Button, InputLabel, Theme } from "@material-ui/core";
import { useAppState } from "../../../state";

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: "1em"
  },
  inputContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "end",
    margin: "1.5em 0 3.5em",
    gap: "1em",
    [theme.breakpoints.down("md")]: {
      margin: "1.5em 0 2em",
      flexDirection: "column"
    }
  },
  textFieldContainer: {
    width: "100%"
  },
  continueButton: {
    minWidth: "90px",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  borderLine: {
    borderTop: "1px solid #d5d9db",
    margin: "2em 0",
    width: "100%"
  },
  clinicianSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "end",
    gap: "2em"
  }
}));

interface RoomNameScreenProps {
  name: string;
  roomName: string;
  setName: (name: string) => void;
  setRoomName: (roomName: string) => void;
  hideRoomNameField: boolean;
  onSubmit: (roomName: string) => void;
}

export default function RoomNameScreen({
  name,
  roomName,
  setName,
  setRoomName,
  hideRoomNameField,
  onSubmit
}: RoomNameScreenProps) {
  const classes = useStyles();
  const { user } = useAppState();

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value?.slice(0, 50));
  };

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        {roomName ? `Join Room ${roomName}` : "Join a Room"}
      </Typography>
      {!user ? (
        <Typography variant="body1">
          Enter your name{!hideRoomNameField ? ", name of the room " : ""} and then click the join button to join as
          client
        </Typography>
      ) : (
        !hideRoomNameField && (
          <Typography variant="body1">Enter name of the room and then click the join button</Typography>
        )
      )}
      <div className={classes.inputContainer}>
        {!user && (
          <div className={classes.textFieldContainer}>
            <InputLabel shrink htmlFor="input-user-name">
              Your Name
            </InputLabel>
            <TextField
              id="input-user-name"
              variant="outlined"
              autoComplete="off"
              fullWidth
              size="small"
              value={name}
              onChange={handleNameChange}
            />
          </div>
        )}
        {!hideRoomNameField && (
          <div className={classes.textFieldContainer}>
            <InputLabel shrink htmlFor="input-room-name">
              Room Name
            </InputLabel>
            <TextField
              autoCapitalize="false"
              id="input-room-name"
              variant="outlined"
              fullWidth
              size="small"
              value={roomName}
              onChange={handleRoomNameChange}
            />
          </div>
        )}
        <Button
          variant="contained"
          color="primary"
          disabled={name.length < 2 || !roomName}
          className={classes.continueButton}
          onClick={() => onSubmit(roomName)}
        >
          Join
        </Button>
      </div>
    </>
  );
}
