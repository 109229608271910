import { CLINICAL_PSYCHOLOGIST_ITEMS } from "./clinicalPsychologist";
import { DIETITIAN_ITEMS } from "./dietitian";
import { GENERAL_PRACTITIONER_ITEMS } from "./generalPractitioner";
import { MENTAL_HEALTH_WORKER_ITEMS } from "./mentalHealthWorker";
import { OCCUPATIONAL_THERAPIST_ITEMS } from "./occupationalTherapist";
import { PSYCHOLOGIST_ITEMS } from "./psychologist";
import { PSYCHOLOGIST_ITEMS as DVA_PSYCHOLOGIST_ITEMS } from "../dvaItems/psychologist";
import { REGISTERED_PSYCHOLOGIST_ITEMS } from "./registeredPsychologist";
import { SOCIAL_WORKER_ITEMS } from "./socialWorker";
import { PRACTICE_NURSE_ITEMS } from "./practiceNurse";
import { MENTAL_HEALTH_NURSE_ITEMS } from "./mentalHealthNurse";
import { NURSE_PRACTITIONER_ITEMS } from "./nursePractitioner";
import { ALLIED_HEALTH_PROFESSIONAL_ITEMS } from "./alliedHealthProfessional";
import { SPEECH_PATHOLOGIST_ITEMS } from "./speechPathologist";
import { ClinicianMedicareRole, MedicareItemFormat } from "./interfaces";

/** without disabled items */
export const MBS_CODE_ITEMS = {
  [ClinicianMedicareRole.ClinicalPsychologists]: [
    ...CLINICAL_PSYCHOLOGIST_ITEMS,
    ...MENTAL_HEALTH_WORKER_ITEMS,
    ...PSYCHOLOGIST_ITEMS,
    ...ALLIED_HEALTH_PROFESSIONAL_ITEMS
  ].filter(({ disabled }) => !disabled),
  [ClinicianMedicareRole.RegisteredPsychologists]: [
    ...REGISTERED_PSYCHOLOGIST_ITEMS,
    ...MENTAL_HEALTH_WORKER_ITEMS,
    ...PSYCHOLOGIST_ITEMS,
    ...ALLIED_HEALTH_PROFESSIONAL_ITEMS
  ].filter(({ disabled }) => !disabled),
  [ClinicianMedicareRole.OccupationalTherapists]: [
    ...OCCUPATIONAL_THERAPIST_ITEMS,
    ...MENTAL_HEALTH_WORKER_ITEMS,
    ...ALLIED_HEALTH_PROFESSIONAL_ITEMS
  ].filter(({ disabled }) => !disabled),
  [ClinicianMedicareRole.SocialWorkers]: [
    ...SOCIAL_WORKER_ITEMS,
    ...MENTAL_HEALTH_WORKER_ITEMS,
    ...ALLIED_HEALTH_PROFESSIONAL_ITEMS
  ].filter(({ disabled }) => !disabled),
  [ClinicianMedicareRole.GeneralPractitioner]: GENERAL_PRACTITIONER_ITEMS.filter(({ disabled }) => !disabled),
  [ClinicianMedicareRole.Dietitian]: [...DIETITIAN_ITEMS, ...ALLIED_HEALTH_PROFESSIONAL_ITEMS].filter(
    ({ disabled }) => !disabled
  ),
  [ClinicianMedicareRole.PracticeNurse]: PRACTICE_NURSE_ITEMS.filter(({ disabled }) => !disabled),
  [ClinicianMedicareRole.MentalHealthNurse]: [...MENTAL_HEALTH_NURSE_ITEMS, ...ALLIED_HEALTH_PROFESSIONAL_ITEMS].filter(
    ({ disabled }) => !disabled
  ),
  [ClinicianMedicareRole.NursePractitioner]: NURSE_PRACTITIONER_ITEMS.filter(({ disabled }) => !disabled),
  [ClinicianMedicareRole.SpeechPathologist]: SPEECH_PATHOLOGIST_ITEMS.filter(({ disabled }) => !disabled),

  // generic items
  [ClinicianMedicareRole.MentalHealthWorker]: MENTAL_HEALTH_WORKER_ITEMS.filter(({ disabled }) => !disabled),
  [ClinicianMedicareRole.Psychologist]: PSYCHOLOGIST_ITEMS.filter(({ disabled }) => !disabled),
  [ClinicianMedicareRole.AlliedHealthProfessional]: ALLIED_HEALTH_PROFESSIONAL_ITEMS
};

export const MBS_CODE_FULL_LIST = [
  ...CLINICAL_PSYCHOLOGIST_ITEMS,
  ...REGISTERED_PSYCHOLOGIST_ITEMS,
  ...OCCUPATIONAL_THERAPIST_ITEMS,
  ...SOCIAL_WORKER_ITEMS,
  ...GENERAL_PRACTITIONER_ITEMS,
  ...DIETITIAN_ITEMS,
  ...PRACTICE_NURSE_ITEMS,
  ...MENTAL_HEALTH_NURSE_ITEMS,
  ...NURSE_PRACTITIONER_ITEMS,
  ...SPEECH_PATHOLOGIST_ITEMS,
  ...MENTAL_HEALTH_WORKER_ITEMS,
  ...PSYCHOLOGIST_ITEMS,
  ...ALLIED_HEALTH_PROFESSIONAL_ITEMS,
  ...DVA_PSYCHOLOGIST_ITEMS
];

export const MBS_CODE_INDIVIDUAL = MBS_CODE_FULL_LIST.filter((code) => code.format === MedicareItemFormat.OneToOne);
export const MBS_CODE_GROUP = MBS_CODE_FULL_LIST.filter((code) => code.format === MedicareItemFormat.Group);
export { ClinicianMedicareRole };
