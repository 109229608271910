import { Button, makeStyles } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  helpButton: {
    display: "contents",
    color: "#3f52ff",

    "&:hover span": {
      textDecoration: "underline"
    }
  },
  refreshButton: {
    marginTop: 8
  },
  refreshButtonText: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  refreshButtonIcon: {
    marginLeft: 8,

    [theme.breakpoints.down("sm")]: {
      marginLeft: 0
    }
  }
}));

interface PermissionsErrorSnackbarMessageProps {
  onStepToStepGuideClick: () => void;
}

const PermissionsErrorSnackbarMessage = ({ onStepToStepGuideClick }: PermissionsErrorSnackbarMessageProps) => {
  const classes = useStyles();

  return (
    <div>
      <div>
        It looks like your browser is blocking the necessary permissions. The best way to solve this is normally to open
        your browser settings, and change the permission to <strong>ALLOW</strong> for access to your camera and
        microphone.
        <br />
        <br />
        Please see our{" "}
        <Button className={classes.helpButton} variant="text" disableRipple onClick={onStepToStepGuideClick}>
          <span>step by step guide here</span>.
        </Button>
        <br />
        <br />
        After you have updated the settings, you should be able to hit refresh below and see the camera and microphone
        access
      </div>
      <Button
        className={classes.refreshButton}
        color="secondary"
        variant="outlined"
        onClick={() => window.location.reload()}
      >
        <span className={classes.refreshButtonText}>Refresh</span>
        <Refresh className={classes.refreshButtonIcon} />
      </Button>
    </div>
  );
};

export default PermissionsErrorSnackbarMessage;
