import { createTheme } from "@material-ui/core";

declare module "@material-ui/core/styles/createTheme" {
  interface Theme {
    sidebarWidth: number;
    sidebarMobileHeight: number;
    brand: string;
    footerHeight: number;
    mobileTopBarHeight: number;
    mobileFooterHeight: number;
    sidebarMobilePadding: number;
    participantBorderWidth: number;
    rightDrawerWidth: number;
    galleryViewBackgroundColor: string;
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    sidebarWidth?: number;
    sidebarMobileHeight?: number;
    brand: string;
    footerHeight: number;
    mobileTopBarHeight: number;
    mobileFooterHeight: number;
    sidebarMobilePadding: number;
    participantBorderWidth: number;
    rightDrawerWidth?: number;
    galleryViewBackgroundColor: string;
  }
}

const defaultTheme = createTheme();

export default createTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "html, body, #root": {
          height: "100%"
        },
        "h1, h2, h3, h4": {
          fontFamily: "'Nunito Sans', sans-serif",
          fontWeight: "bold"
        }
      }
    },
    MuiButton: {
      root: {
        fontFamily: "'Nunito Sans', sans-serif",
        borderRadius: "4px",
        textTransform: "none",
        color: "rgb(40, 42, 43)",
        fontSize: "0.9rem",
        transition: defaultTheme.transitions.create(["background-color", "box-shadow", "border", "color"], {
          duration: defaultTheme.transitions.duration.short
        })
      },
      text: {
        padding: "6px 14px"
      },
      contained: {
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none"
        }
      },
      outlinedPrimary: {
        border: "1px solid #3f52ff",
        "&:hover": {
          border: "2px solid rgb(1, 85, 137)"
        }
      },
      containedPrimary: {
        backgroundImage: "linear-gradient(53.59deg,#3f52ff -11.82%,#00109f 45%,#101e9f 50%,#8692ff 111.69%)",
        backgroundSize: "200% auto",
        transition:
          "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-position 0.5s",

        "&:hover": {
          backgroundPosition: "100% 0"
        },
        "&$disabled": {
          backgroundImage: "none"
        }
      },
      startIcon: {
        marginRight: "6px"
      }
    },
    MuiTypography: {
      body1: {
        color: "rgb(40, 42, 43)",
        fontSize: "0.9rem"
      }
    },
    MuiInputBase: {
      root: {
        fontSize: "0.9rem"
      }
    },
    MuiSelect: {
      root: {
        fontFamily: "'Nunito Sans', sans-serif",
        padding: "0.85em"
      }
    },
    MuiDialogActions: {
      root: {
        padding: "16px"
      }
    },
    MuiTextField: {
      root: {
        color: "rgb(40, 42, 43)"
      }
    },
    MuiInputLabel: {
      root: {
        color: "rgb(40, 42, 43)",
        fontSize: "1.1rem",
        marginBottom: "0.2em",
        fontWeight: 500
      }
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: "rgb(136, 140, 142)"
      }
    },
    MuiSwitch: {
      root: {
        width: 42,
        height: 18,
        padding: 0,
        display: "flex",
        marginRight: "0.5em",
        overflow: "visible"
      },
      switchBase: {
        padding: 2,
        color: "#D54141",
        top: -1,
        left: -6,
        marginTop: -4,
        "& input": {
          left: "0",
          width: "200%"
        },
        "&$checked": {
          transform: "translateX(24px)",
          "&$disabled": {
            "& + $track": {
              opacity: "0.5"
            }
          },
          "& input": {
            left: "-100%"
          }
        }
      },
      colorSecondary: {
        "&$checked": {
          // Controls checked color for the thumb
          color: "#25BE69"
        }
      },
      input: {
        transition: "left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
      },
      thumb: {
        width: 24,
        height: 24,
        boxShadow: "none"
      },
      track: {
        opacity: 1,
        borderRadius: "16px",
        backgroundColor: "#FFF",
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
        "$checked$checked + &": {
          opacity: 1,
          borderRadius: "16px",
          backgroundColor: "#FFF"
        }
      }
    }
  },
  typography: {
    fontFamily: "Nunito Sans, sans-serif"
  },
  palette: {
    primary: {
      main: "#027AC5"
    }
  },
  brand: "#E22525",
  footerHeight: 72,
  mobileFooterHeight: 56,
  sidebarWidth: 300,
  sidebarMobileHeight: 90,
  sidebarMobilePadding: 8,
  participantBorderWidth: 2,
  mobileTopBarHeight: 52,
  rightDrawerWidth: 320,
  galleryViewBackgroundColor: "#121C2D"
});
